/* eslint-disable */

import axios from "axios";
import constant from "./constant";

const vendorloginUrl = "vendor/login"
const vendordataUrl = "vendor/getvendordata"
const getgigsdataUrl = "gigs/getgigsdata"
const addgigsUrl = "gigs/addgigsdata"
const getvendorcatdataUrl = "gigs/getvendorcatdata"
const getvendorsubcatdataUrl = "gigs/getvendorsubcatdata"
const deletegigdataUrl = "gigs/deletegig"
const getgigsdatabyidUrl = "gigs/getgigsdatabyid"
const getallvendorsubcatdataUrl = 'gigs/getallvendorsubcatdata'
const updatevendordataUrl = 'vendor/updatevendordata'
const vendordashboarddataUrl = "vendor/vendordashboarddata"
const addvendorstripedataUrl = "payment/addvendorstripedata"
const addvendorstripebankdataUrl = "payment/addvendorstripebankdata"
const getvendorbankdataurl ="payment/getvendorbankdata"
const deletevendorstripebankdataurl ="payment/deletevendorstripebankdata"
const checkvendoraccountstripeUrl ="payment/checkvendoraccountstripe"
const checkuserbankexistsUrl = "payment/checkuserbankexists"


function getauthtoken(){
    let token = localStorage.getItem('AUTH_TOKEN');
    let Authtoken = '';
    if(token !=null && token !='' && token !=undefined){
        Authtoken = token;
    }
    const config = {
      headers: { 'XAuthorization': `Bearer ${Authtoken}` }
    };
    return config;

  }

const client = axios.create({
    baseURL: constant.API_URL,
  });

  export class ApiService {


vendorloginPostRequest(request) {
  return client.post(vendorloginUrl, request)
}

vendordataPostRequest(request) {
  return client.post(vendordataUrl, request, getauthtoken())
}

gigsdataRequest() {
  return client.get(getgigsdataUrl, getauthtoken())
}

addgigsdataPostRequest(request) {
  return client.post(addgigsUrl,request ,  getauthtoken())
}

gigsvendorcatdataRequest() {
  return client.get(getvendorcatdataUrl, getauthtoken())
}
getvendorsubcatdata(request) {
  return client.post(getvendorsubcatdataUrl, request, getauthtoken())
}

deletegigdata(request) {
  return client.post(deletegigdataUrl, request, getauthtoken())
}
getgigsdatabyid(request) {
  return client.post(getgigsdatabyidUrl, request, getauthtoken())
}
getallvendorsubcatdataRequest() {
  return client.get(getallvendorsubcatdataUrl, getauthtoken())
}
updatevendordata(request) {
  return client.post(updatevendordataUrl, request, getauthtoken())
}
vendordashboarddata(request) {
  return client.post(vendordashboarddataUrl, request, getauthtoken())
}
addvendorstripedata(request) {
  return client.post(addvendorstripedataUrl, request, getauthtoken())
}

addvendorstripebankdata(request) {
  return client.post(addvendorstripebankdataUrl, request, getauthtoken())
}

  getvendorbankdataRequest() {
  return client.get(getvendorbankdataurl, getauthtoken())
}
deletevendorstripebankdata(request) {
  return client.post(deletevendorstripebankdataurl, request, getauthtoken())
}
checkvendoraccountstripe() {
  return client.get(checkvendoraccountstripeUrl, getauthtoken())
}
checkuserbankexists() {
  return client.get(checkuserbankexistsUrl, getauthtoken())
}
}
